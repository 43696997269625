import React from "react"
import { LockOutlined, SendOutlined } from "@ant-design/icons"
import { Form } from "@ant-design/compatible"
import "@ant-design/compatible/assets/index.css"
import { Input, Button } from "antd"

import { signIn } from "../api/account"
import { addLog } from "../api/log"
import { MOBILE, USERID, USERNAME } from "../utils/constant"
import { getRandIP } from "../utils/helper"
import "./SignIn.css"

function SignIn(props) {
  const { getFieldDecorator } = props.form

  function handleSubmit(e) {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        signIn(values, () => {
          props.form.resetFields()
          props.successCallback()
        })

        if (typeof window !== "undefined") {
          const loginIP = window.localStorage.getItem("ip") ? localStorage.getItem("ip") : getRandIP();
          window.localStorage.setItem("ip", loginIP);
          let loginTime = new Date().toISOString()
          loginTime = loginTime.replace("T", " ").slice(0, loginTime.length - 5)
          addLog({
            desc: "密码认证登录",
            url: window.location.href,
            loginIp: loginIP,
            loginTime,
            mobile: window.localStorage.getItem(MOBILE),
            userId: window.localStorage.getItem(USERID) ? window.localStorage.getItem(USERID) : 0,
            username: window.localStorage.getItem(USERNAME) ? window.localStorage.getItem(USERNAME) : "游客",
            userType: window.localStorage.getItem(USERID) ? 1 : 0,
          });
        }
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="tabpane-container">
        <h2>账号密码登录</h2>
        <Form.Item>
          {getFieldDecorator("mobile", {
            rules: [{ required: true, message: "请输入手机号" }],
          })(
            <Input
              prefix={<SendOutlined />}
              placeholder="手机号，比如：18812345678"
              allowClear
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "请输入密码" }],
          })(
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="请输入密码"
              allowClear
            />
          )}
        </Form.Item>
        <Button type="primary" htmlType="submit">
          登录
        </Button>
      </div>
    </Form>
  )
}

export default Form.create()(SignIn)
