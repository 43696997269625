import { axiosBackEnd } from "../utils/helper"

export const getJobList = async (currentPage, pageSize, type = null) => {
  if (type !== null) {
    return await axiosBackEnd.get(
      `/api/fake/recruit?currentPage=${currentPage}&pageSize=${pageSize}&type=${type}`
    )
  } else {
    return await axiosBackEnd.get(
      `/api/fake/recruit?currentPage=${currentPage}&pageSize=${pageSize}`
    )
  }
}

export const getJobDetail = async id => {
  return await axiosBackEnd.get(
    `/api/fake/recruit/${id}`
  )
}

export const submitJob = async values => {
  return await axiosBackEnd.post(
    `/api/fake/recruit`,
    values
  )
}


export const getJobSearch = async word => {
  return await axiosBackEnd.get(
    `/api/fake/recruit/search/${word}`
  )
}
