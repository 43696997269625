import React, { useEffect } from "react"

import styles from "./footer.module.css"
import link from "../icons/link.svg"
import leetcode from "../icons/leetcode.svg"
import chat from "../icons/chat.svg"
import egg from "../icons/egg.svg"
import logo from "../icons/logo.svg"

import { addLog } from "../api/log"
import { MOBILE, USERID, USERNAME } from "../utils/constant"
import { getRandIP } from "../utils/helper"

export default () => {
  useEffect(() => {
    init()
  })

  const init = async () => {
    if (typeof window !== "undefined") {
      const loginIP = window.localStorage.getItem("ip") ? localStorage.getItem("ip") : getRandIP();
      window.localStorage.setItem("ip", loginIP);
      let loginTime = new Date().toISOString()
      loginTime = loginTime.replace("T", " ").slice(0, loginTime.length - 5)
      await addLog({
        desc: "访问" + window.location.href + "页面",
        url: window.location.href,
        loginIp: loginIP,
        loginTime,
        mobile: window.localStorage.getItem(MOBILE),
        userId: window.localStorage.getItem(USERID) ? window.localStorage.getItem(USERID) : 0,
        username: window.localStorage.getItem(USERNAME) ? window.localStorage.getItem(USERNAME) : "游客",
        userType: window.localStorage.getItem(USERID) ? 1 : 0,
      });
    }
  }

  return (
    <div>
      <div className={styles.topContainer}>
        <div className={styles.moreContainer}>
          <h4>其他招聘网站</h4>

          <div className={styles.row}>
            <a
              rel="noopener noreferrer"
              href="https://www.zhipin.com/beijing/"
              target="_blank"
            >
              北京招聘网
            </a>
          </div>
          <div className={styles.row}>
            <a
              rel="noopener noreferrer"
              href="https://www.zhipin.com/shanghai/"
              target="_blank"
            >
              上海招聘网
            </a>
          </div>
          <div className={styles.row}>
            <a
              rel="noopener noreferrer"
              href="https://www.zhipin.com/guangzhou/"
              target="_blank"
            >
              广州招聘网
            </a>
          </div>
        </div>
        <div className={styles.openContainer}>
          <h4>友情链接</h4>
          <div className={styles.row}>
            <a
              rel="noopener noreferrer"
              href="http://www.kanzhun.com/gso7575.html"
              target="_blank"
            >
              顺丰速运
            </a>
          </div>
          <div className={styles.row}>
            <a
              rel="noopener noreferrer"
              href="http://www.kanzhun.com/gso12974.html"
              target="_blank"
            >
              中国农业银行
            </a>
          </div>
          <div className={styles.row}>
            <a
              rel="noopener noreferrer"
              href="http://www.kanzhun.com/gso3187.html"
              target="_blank"
            >
              肯德基
            </a>
          </div>
        </div>
        <div className={styles.noticeContainer}>
          <h4>帮助</h4>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://hellobuluo.com/article/user/agreement/"
          >
            用户协议
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://hellobuluo.com/article/user/private-policy/"
          >
            隐私政策
          </a>
        </div>
      </div>

      <div className={styles.hr}></div>

      <div className={styles.bottomContainer}>
        <p>本网站所有资料归奇点网络版权所有</p>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="http://beian.miit.gov.cn/"
        >
          粤ICP备18089782号
        </a>
      </div>
    </div>
  )
}
