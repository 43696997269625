import axios from "axios"
import { USERNAME, AVATAR, USERID, EMAIL, TOKEN } from "./constant"
import { BASE_URL } from "gatsby-env-variables"
import { message } from "antd"

export const axiosBackEnd = axios.create({ baseURL: BASE_URL })
// 做错误处理
axiosBackEnd.interceptors.response.use(
  function (response) {
    if (response.data.code !== "0000") {
      message.warn(response.data.msg)
    }
    return response
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

export const axiosImg = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
})

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const toBlob = (base64, fileType) => {
  let bytes
  if (typeof window !== "undefined") {
    bytes = window.atob(base64)
  }
  let n = bytes.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bytes.charCodeAt(n)
  }
  return new Blob([u8arr], { type: fileType })
}

export const scrollToTop = () => {
  let sTop = document.documentElement.scrollTop || document.body.scrollTop
  if (sTop > 0) {
    if (typeof window !== "undefined") {
      window.requestAnimationFrame(scrollToTop)
      window.scrollTo(0, sTop - sTop / 8)
    }
  }
}

export const hasCookie = key => {
  const cookie = document.cookie.split(";")
  for (let item of cookie) {
    let dict = item.trim().split("=")
    if (dict[0] === key) {
      return true
    }
  }
  return false
}

export const getCookie = key => {
  if (typeof document !== "undefined") {
    const cookie = document.cookie.split(";")
    for (let item of cookie) {
      let dict = item.trim().split("=")
      if (dict[0] === key) {
        return dict[1]
      }
    }
  }
  return null
}

export const setCookie = (key, value) => {
  const year = 60 * 60 * 24 * 365
  document.cookie = `${key}=${value}; domain=.mdnice.com; path=/;max-age=${year};`
}

export const removeCookie = key => {
  document.cookie = `${key}=; domain=.mdnice.com; path=/; expires=${new Date(
    0
  )};`
}

export const convert2Articles = nodes => {
  const articles = nodes.map(node => {
    const { excerpt } = node
    const { date, title, cover, tag } = node.frontmatter
    const { slug } = node.fields
    return { date, title, cover, tag, excerpt, slug }
  })

  return articles
}

// 根据日期排序
export const sortArticles = articles => {
  const sortedArticles = articles.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  )
  return sortedArticles
}

export const convertSpace = size => {
  let unit = "byte"
  let tmpSize = size
  if (tmpSize > 1024) {
    tmpSize = (tmpSize / 1024).toFixed(2)
    unit = "KB"
  }
  if (tmpSize > 1024) {
    tmpSize = (tmpSize / 1024).toFixed(2)
    unit = "MB"
  }
  return tmpSize + " " + unit
}

export const queryParse = () => {
  let search
  if (typeof window !== "undefined") {
    search = window.location.search
  }
  if (!search) return {}
  const queryString = search[0] === "?" ? search.substring(1) : search
  const query = {}
  queryString.split("&").forEach(queryStr => {
    const [key, value] = queryStr.split("=")
    /* istanbul ignore else */
    if (key) query[decodeURIComponent(key)] = decodeURIComponent(value)
  })
  return query
}

// 是否为PC端
export const isPC = () => {
  var flag = true
  if (typeof window !== "undefined") {
    var userAgentInfo = window.navigator.userAgent
    var Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod",
    ]
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false
        break
      }
    }
  }
  return flag
}
export const getRandIP = () => {
  var ip = Math.floor(Math.random() * 256)
  for (var i = 0; i < 3; i++) {
    ip = ip + "." + Math.floor(Math.random() * 256)
  }
  return ip
}
