import React, { useState, useEffect } from "react"

import NavItem from "../components/NavItem"
import logo from "../icons/logo.svg"
import menu from "../icons/menu.svg"

import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Modal, Avatar, Button, Input, Checkbox, message } from "antd"
import { navigate } from "gatsby"

import "./navbar.css"
import { USERNAME, AVATAR, USERID, EMAIL, TOKEN } from "../utils/constant"
import { getCookie, removeCookie, queryParse } from "../utils/helper"
import SignIn from "../components/SignIn"
import { getJobSearch } from "../api/job"
import Reset from "../components/Reset"

const { Search } = Input

function Navbar(props) {
  const query = queryParse()
  const navs = props.navs
  const [userId, setUserId] = useState("")
  const [visible, setVisible] = useState(query.login ? true : false)
  const [username, setUsername] = useState("")
  const [activeTab, setActiveTab] = useState("login")
  const [searchWord, setSearchWord] = useState("")

  const successSignIn = () => {
    setVisible(false)
    setUserId(localStorage.getItem(USERID))
    setUsername(localStorage.getItem(USERNAME))

    // 先通过刷新的方式更新状态
    if (typeof window !== "undefined") {
      window.location.reload()
    }
  }

  useEffect(() => {
    setUserId(localStorage.getItem(USERID))
    setUsername(localStorage.getItem(USERNAME))
  }, [])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    setVisible(false)
  }

  const handleExit = () => {
    localStorage.removeItem(USERNAME)
    localStorage.removeItem(USERID)
    setUsername("")
    setUserId("")
    // 先通过刷新的方式更新状态
    if (typeof window !== "undefined") {
      window.location.reload()
    }
  }

  const handlePublish = () => {
    const userId = localStorage.getItem(USERID)
    if (userId) {
      navigate("/jobs/upload")
    } else {
      message.warning("请登录后进行职位发布")
      showModal()
    }
  }

  const onSearch = async (value) => {
    const res = await getJobSearch(value);
    console.log(res)
    if (res.data.code === "0000") {
      navigate("/jobs/search")
    } else {
      setSearchWord("")
    }
  }

  const handleSearchChange = (event) => {
    setSearchWord(event.target.value)
  }

  const userMenu = (
    <Menu>
      <Menu.Item>
        <a onClick={handleExit}>退出登录</a>
      </Menu.Item>
    </Menu>
  )

  let headerStyle = "--none"
  if (props.isNavbarDisplay) {
    if (props.isFixedNavbar) {
      headerStyle = "mdnice-navbar-fixed"
    } else {
      headerStyle = "mdnice-navbar"
    }
  }

  let display
  if (activeTab === "login") {
    display = (
      <SignIn successCallback={successSignIn} setActiveTab={setActiveTab} />
    )
  } else if (activeTab === "reset") {
    display = <Reset successCallback={() => setActiveTab("login")} />
  }
  return (
    <>
      <header className={headerStyle}>
        <div className="mdnice-navbar-container">
          <div className="mdnice-navbar-left">
            <a href="/">
              <img src={logo} className="mdnice-icon" alt="mdnice-icon" />
            </a>
            <h2 style={{ marginLeft: "5px", fontSize: "18px", marginBottom: "0" }}>Hello 部落</h2>
          </div>
          <nav className="mdnice-navbar-right">
            {navs.map(item => (
              <NavItem
                key={item.text}
                path={item.path}
                text={item.text}
                activeClass="mdnice-navbar-btn --navbar-btn-active"
                defaultClass="mdnice-navbar-btn"
              />
            ))}

            {userId ? (
              <>
                <Avatar size="small">{username && username[0]}</Avatar>
                <Dropdown
                  trigger="click"
                  overlay={userMenu}
                  placement="bottomRight"
                >
                  <a
                    className="ant-dropdown-link"
                    style={{ paddingLeft: "10px" }}
                    onClick={e => e.preventDefault()}
                  >
                    {username} <DownOutlined />
                  </a>
                </Dropdown>
              </>
            ) : (
              <a id="mdnice-navbar-btn-login" className="mdnice-navbar-btn-login" onClick={showModal}>
                登录 / 注册
              </a>
            )}

            <Button type="primary" onClick={handlePublish}>发布</Button>

            <div className="mdnice-search">
              <Search placeholder="请输入你要搜索的岗位" onSearch={onSearch} value={searchWord} onChange={handleSearchChange} />
            </div>

            <div className="mdnice-menu">
              <img
                src={menu}
                className="mdnice-menu-icon"
                alt="menu-icon"
                onClick={props.menuClick}
              />
            </div>
          </nav>
        </div>
      </header>
      <div className={props.isSpacerDisplay ? "navbar-spacer" : "--none"} />
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={400}
        className="login-modal"
      >
        <div>
          <div className="login-tab">{display}</div>
          <div className="mdnice-user-dialog-footer">
            <p>
            </p>
            {activeTab === "login" ? (
              <a onClick={() => setActiveTab("reset")}>
                用户注册 &gt;
              </a>
            ) : (
              <a onClick={() => setActiveTab("login")}>用户登录 &gt;</a>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Navbar
