import React, { useState } from "react"
import { Drawer } from "antd"
import { Helmet } from "react-helmet"

import Navbar from "./navbar"
import NavItem from "../components/NavItem"
import Footer from "./footer"

import "./index.css"
import { useStaticQuery, graphql } from "gatsby"

const Layout = ({
  children,
  isSpacerDisplay,
  isNavbarDisplay,
  isFixedNavbar,
  title,
}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          navs {
            path
            text
          }
        }
      }
    }
  `)

  const navs = site.siteMetadata.navs

  const [state, setState] = useState({
    isMenuOpen: false,
    isSpacerDisplay: isSpacerDisplay === undefined ? true : isSpacerDisplay,
    isNavbarDisplay: isNavbarDisplay === undefined ? true : isNavbarDisplay,
    isFixedNavbar: isFixedNavbar === undefined ? true : isFixedNavbar,
  })

  function handleMenuClick() {
    setState(preState => ({
      ...preState,
      isMenuOpen: true,
    }))
  }

  function handleDrawerClose() {
    setState(preState => ({ ...preState, isMenuOpen: false }))
  }

  return (
    <div className="mdnice-page-container">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="奇点网络,markdown 编辑器,mdnice,github 报告,小工具"/>
        <meta name="description" content="奇点网络开发的 mdnice 编辑器是一款 Markdown 微信编辑器,拥有良好的兼容性、海量全部职位样式、免费的图床、强大的技术团队，提供文章一键排版，同时支持知乎、微信订阅号等多个平台。"/>
        <title>{title}</title>
      </Helmet>
      <Navbar
        menuClick={handleMenuClick}
        isSpacerDisplay={state.isSpacerDisplay}
        isNavbarDisplay={state.isNavbarDisplay}
        isFixedNavbar={state.isFixedNavbar}
        navs={navs}
      />
      <Drawer
        placement="right"
        visible={state.isMenuOpen}
        getContainer={false}
        onClose={handleDrawerClose}
        zIndex={15}
        bodyStyle={{ height: "100%" }}
        width="100%"
      >
        <div className="drawer-content-container">
          {navs.map(item => (
            <NavItem
              key={item.text}
              path={item.path}
              text={item.text}
              activeClass="drawerItem drawerItem-active"
              defaultClass="drawerItem"
              toggleDrawerClose={handleDrawerClose}
            />
          ))}
        </div>
      </Drawer>
      <div className="content-container">{children}</div>
      <div className="page-footer">
        <Footer />
      </div>
    </div>
  )
}
export default Layout
