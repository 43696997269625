import { axiosBackEnd, setCookie } from "../utils/helper"
import { USERNAME, AVATAR, USERID, EMAIL, TOKEN, MOBILE } from "../utils/constant"
import { message } from "antd"

const option = {
  headers: {
    "Content-Type": "application/json",
  },
}

export function signIn(req, callback) {
  axiosBackEnd
    .post("/api/fake/login/signin", req, option)
    .then(res => {
      if (res.data.code === "0000") {
        const { data } = res.data
        localStorage.setItem(USERNAME, data.userName)
        localStorage.setItem(MOBILE, data.mobile)
        localStorage.setItem(USERID, data.id)
        message.success("用户登录成功")
        callback()
      }
    })
    .catch(err => {
      console.error(err)
    })
}

export function sendSignupMobile(mobile, callback) {
  axiosBackEnd
    .post(`/api/fake/login/mobile/${mobile}`, {}, option)
    .then(res => {
      const { data } = res
      if (data.success) {
        message.success("短信发送成功")
        callback()
      }
    })
    .catch(err => {
      message.error(err.response)
    })
}

export function signup(req, callback) {
  axiosBackEnd
    .post("/api/fake/login/signup", req, option)
    .then(res => {
      const { data } = res
      if (data.code === "0000") {
        message.success("用户注册成功")
        callback()
      }
    })
    .catch(err => {
      message.error(err.response)
    })
}
