import React, { useState } from "react"
import {
  LockOutlined,
  SendOutlined,
  SafetyOutlined,
} from "@ant-design/icons"
import { Form } from "@ant-design/compatible"
import "@ant-design/compatible/assets/index.css"
import { Input, Button, Row, Col, Checkbox } from "antd"

import { signup, sendSignupMobile } from "../api/account"
import { RANDOM_CODE_COUNTDOWN } from "../utils/constant"
import "./Reset.css"

function Reset(props) {
  const [isCodeBtnDisabled, setCodeBtnDisabled] = useState(false)
  const [codeBtnText, setCodeBtnText] = useState("获取验证码")

  const { form, successCallback } = props
  const { getFieldDecorator } = form

  let countdownNum = RANDOM_CODE_COUNTDOWN
  function setCountdown() {
    if (countdownNum == 0) {
      setCodeBtnDisabled(false)
      setCodeBtnText("获取验证码")
      countdownNum = RANDOM_CODE_COUNTDOWN
    } else {
      setCodeBtnDisabled(true)
      setCodeBtnText("重新发送(" + countdownNum + ")")
      countdownNum--
      setTimeout(function () {
        setCountdown()
      }, 1000)
    }
  }

  const sendCodeEmail = e => {
    const mobile = form.getFieldValue("mobile")
    // 不等待请求返回先给提示，以免用户等待多次点击
    setCountdown()
    sendSignupMobile(mobile, () => { })
  }

  function handleSubmit(e) {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        signup(values, () => {
          form.resetFields()
          successCallback()
        })
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="tabpane-container">
        <h2>修改密码</h2>
        <Form.Item>
          {getFieldDecorator("mobile", {
            rules: [{ required: true }],
          })(
            <Input
              prefix={<SendOutlined />}
              placeholder="手机号，比如：18812345678"
              allowClear
            />
          )}
        </Form.Item>
        <Form.Item>
          <Row gutter={16}>
            <Col span={15}>
              <Form.Item style={{ marginBottom: "0px" }}>
                {getFieldDecorator("code", {
                  rules: [{ required: true }],
                })(
                  <Input
                    prefix={<SafetyOutlined />}
                    placeholder="6 位短信验证码"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={9}>
              <Button disabled={isCodeBtnDisabled} onClick={sendCodeEmail}>
                {codeBtnText}
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("userName", {
            rules: [{ required: true }],
          })(
            <Input
              prefix={<SendOutlined />}
              placeholder="用户名，比如：张三"
              allowClear
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true }],
          })(<Input.Password prefix={<LockOutlined />} placeholder="密码" />)}
        </Form.Item>
        <Checkbox >
          <span>
            同意{" "}
            <a
              href="https://hellobuluo.com/article/user/agreement/"
              target="_blank"
            >
              用户协议
              </a>{" "}
              和{" "}
            <a
              href="https://hellobuluo.com/article/user/private-policy/"
              target="_blank"
            >
              隐私政策
              </a>
          </span>
        </Checkbox>
        <Button className="reset-sumbit-btn" type="primary" htmlType="submit">
          注册
        </Button>
      </div>
    </Form>
  )
}

export default Form.create()(Reset)
