import React from "react"
import { navigate } from "gatsby"
import { Location } from "@reach/router"
import "./NavItem.css"

function NavItem(props) {
  return (
    <Location>
      {({ location }) => {
        const isActive =
          (props.path === "/" && location.pathname === props.path) ||
          (location.pathname.match(`^${props.path}`) && props.path.length > 1)
        return (
          <>
            <a
              onClick={() => {
                isActive && props.toggleDrawerClose
                  ? props.toggleDrawerClose()
                  : navigate(props.path)
              }}
              className={isActive ? props.activeClass : props.defaultClass}
            >
              {props.text}
            </a>
            <span className="mdnice-navbar-btn-divider"></span>
          </>
        )
      }}
    </Location>
  )
}

export default NavItem
